import TypeTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-pigmentation/type/TypeTemplate';
import {
  FACIAL_SPOT_LOCATIONS,
  GENDER,
  SPOT_OCCURRENCE,
  SPOT_OCCURRENCE_VALUES_COMMON
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinPigmentation/Type/TypeTemplate'
};

const createStory = componentProps => () => ({
  components: { TypeTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <type-template v-bind='$options.componentProps' />
  </div>`
});

const SPOT_OCCURRENCE_VALUES_OLDER_THAN_40 = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AT_MIDDLE_AGE
];

export const OlderThan40 = createStory({
  gender: GENDER.FEMALE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.AFTER_AN_INJURY],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_OLDER_THAN_40,
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN]
});

const SPOT_OCCURRENCE_VALUES_PREGNANCY = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.DURING_PREGNANCY
];

export const OlderThan20AndFemale = createStory({
  gender: GENDER.FEMALE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.WHEN_A_CHILD],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_PREGNANCY,
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN]
});

const SPOT_OCCURRENCE_VALUES_HRT = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AFTER_STARTING_HRT
];

export const TakingHRT = createStory({
  gender: GENDER.FEMALE,
  firstSpotsOccurrence: [SPOT_OCCURRENCE.WHEN_A_CHILD, SPOT_OCCURRENCE.AFTER_STARTING_HRT],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_HRT,
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN]
});

const SPOT_OCCURRENCE_VALUES_BIRTH_CONTROL = [
  ...SPOT_OCCURRENCE_VALUES_COMMON,
  SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL
];

export const TakingBirthControlPills = createStory({
  gender: GENDER.FEMALE,
  firstSpotsOccurrence: [
    SPOT_OCCURRENCE.WHEN_A_CHILD,
    SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL
  ],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_BIRTH_CONTROL,
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN]
});

export const NotSure = createStory({
  gender: GENDER.FEMALE,
  firstSpotsOccurrence: ['unsure'],
  spotOccurrenceValues: SPOT_OCCURRENCE_VALUES_COMMON,
  facialSpotLocations: [FACIAL_SPOT_LOCATIONS.FOREHEAD, FACIAL_SPOT_LOCATIONS.CHIN]
});
